import { useEffect } from "react";
import { useState } from "react";

import isSSR from "@Js/Utils/isSSR";

/**
 * @const useMediaQuery
 */
const useMediaQuery = (query: string) => {
    if (isSSR()) {
        return false;
    }

    const [matches, setMatches] = useState(() => {
        return matchMedia(query).matches;
    });

    const onMediaQueryListChange = (event: MediaQueryListEvent) => {
        setMatches(event.matches);
    };

    useEffect(() => {
        const mediaQueryList = matchMedia(query);

        mediaQueryList.addEventListener("change", onMediaQueryListChange);

        return () => {
            mediaQueryList.removeEventListener("change", onMediaQueryListChange);
        };
    }, []);

    return matches;
};

export default useMediaQuery;
